import React from "react";
import TeamMember from "../components/TeamPage/TeamMember";
import { useEffect } from "react";

function Team() {
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
  return (
    <div>
      <TeamMember />
    </div>
  );
}

export default Team;
