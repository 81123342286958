import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-upper-container footer-container">
        <div className="footer-upper-container-content">
          <div className="footer-upper-left footer-upper"> We're Here</div>
          <div className="footer-upper-right footer-upper">
            <div className="footer-upper-right-content footer-upper-right-content-top">
              Let's Talk
            </div>
            <div className="footer-upper-right-content footer-upper-right-content-bottom">
              <div>Share your thoughts with us</div>
              <div>
                <div></div>
                <a className="ipf-mail">ipf@nitc.ac.in</a>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-lower-container footer-container">
        <div className="social-media-container">
          <div className="social-media-icon">
            <a
              href="https://www.instagram.com/ipf.nitc/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-media"
                src="./images/instagram.svg"
                alt="instagram"
              ></img>
            </a>
          </div>
          <div className="social-media-icon">
            <a
              href="https://www.linkedin.com/company/industrial-and-planning-forum-nit-calicut/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-media"
                src="./images/linkedin.svg"
                alt="instagram"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
