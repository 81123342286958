import React from 'react'
import styles from './Series.module.css'
import { useNavigate } from 'react-router-dom'

function Series() {
  const navigate = useNavigate()

  const NavigateToSeries = () => {
    navigate('/Series')
  }

    return (
      <div className={styles["series-wrapper"]}>
        <div className={styles["heading"]}> SERIES </div>
        <div className={styles["series-holder"]}>
          <div
            className={styles["series-card"]}
            onClick={NavigateToSeries}
            style={{ cursor: "pointer" }}
          >
            <div
              className={styles["image-holder"]}
              style={{
                backgroundImage: "url(./images/Athenaeum.jpg)",
              }}
            ></div>
            <div className={styles["series-text"]}>NEWSLETTER</div>
          </div>
          <div className={styles["series-card"]}>
            <div
              className={styles["image-holder"]}
              style={{
                backgroundImage: "url(./images/StraightON.jpg)",
              }}
            ></div>
            <div className={styles["series-text"]}>TALK SHOW</div>
          </div>
        </div>
      </div>
    );
}

export default Series
