import React from "react";
import AboutUs from "../components/AboutUs";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div style={{ paddingTop: "5vh", background: "#01131A" }}>
      <AboutUs />
    </div>
  );
}

export default About;
