import React from "react";
import { useEffect } from "react";
import FormComp from "../components/FormComp"

function Form() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div style={{ paddingTop: "12vh", background: "#000000" }}>
      <FormComp/>
    </div>
  );
}

export default Form;
