// Navbar.js
import React, { useRef } from "react";
import { useState } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import styles from "./nav.module.css";

function Navbar() {
  const [isHome, setIsHome] = useState(false);
  const navRef = useRef();

  const showNavBar = () => {
    const navContainer = navRef.current;
    navContainer.classList.toggle(styles["responsive_nav"]);
    const homeLink = navContainer.querySelector('a[href="/#"]');
    if (homeLink) {
      homeLink.classList.toggle("visible");
    }
  };

  return (
    <header className={styles["navbar"]}>
      <NavLink
        to="/"
        className={styles["home-link"]}
        duration={500}
        onClick={() => {
          setIsHome(true);
        }}
      >
        Home
      </NavLink>

      <nav ref={navRef}>
        <NavLink to="/Events" duration={500}>
          Event
        </NavLink>

        <NavLink
          to="/Team"
          duration={500}
          onClick={() => {
            setIsHome(false);
          }}
        >
          Team
        </NavLink>

        <NavLink
          to="/MicRegistations"
          duration={500}
          onClick={() => {
            setIsHome(false);
          }}
        >
          MIC Registations
        </NavLink>

        {isHome ? (
          <Link to="aboutUsSection" duration={500}>
            About Us
          </Link>
        ) : (
          <NavLink
            to="/About"
            duration={500}
            onClick={() => {
              setIsHome(false);
            }}
          >
            About Us
          </NavLink>
        )}
        <button
          className={`${styles["nav-btn"]} ${styles["nav-close-btn"]}`}
          onClick={showNavBar}
        >
          <FaTimes />
        </button>
      </nav>
      <button className={styles["nav-btn"]} onClick={showNavBar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
