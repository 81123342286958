import React from "react";
import { useState, useEffect } from "react";
import styles from "./teamMember.module.css";
import { db } from "../../firebaseConnect";
import { collection, onSnapshot } from "firebase/firestore";
import Teamcards from "./Teamcards";
import Designcards from "./Designcards";
import Marketingcards from "./Marketingcards";
import Contentcards from "./Contentcards";
import Techcards from "./Techcards";

function TeamMember() {
  const [heads, setHeads] = useState([]);
  const [design, setDesign] = useState([]);
  const [marketing, setMarketing] = useState([]);
  const [content, setContent] = useState([]);
  const [tech, setTech] = useState([]);
  const membersColref = collection(db, "members");
  
  useEffect(() => {
    onSnapshot(membersColref, (s) => {
      const temp_heads = [];
      const temp_design = [];
      const temp_marketing = [];
      const temp_content = [];
      const temp_tech = [];
      s.forEach((doc) => {
        let data = { ...doc.data() }
        // console.log(data.team)
        if (data.team.toLowerCase() === "head") {
          temp_heads.push(data)
        }
        if (data.team.toLowerCase() === "design") {
          temp_design.push(data);
        }
        if (data.team.toLowerCase() === "marketing") {
          temp_marketing.push(data);
        }
        if (data.team.toLowerCase() === "content") {
          temp_content.push(data);
        }
        if (data.team.toLowerCase() === "tech") {
          temp_tech.push(data);
          console.log(data)
        }
      });
      setHeads(temp_heads)
      setDesign(temp_design)
      setMarketing(temp_marketing)
      setContent(temp_content)
      setTech(temp_tech)
    });
  }, []);

  return (
    <div className={styles["TeampageWrapper"]}>
      <div className={styles["TH"]}>
        <h2>OUR TEAM</h2>
        <h3>TEAM HEADS</h3>
      </div>
      <div className={styles["TeamMember"]}>
        {heads?.map((item, index) => {
          return (
            <div>
              <Teamcards key={index} member={item} />
            </div>
          );
        })}
      </div>
      <div className={styles["TH"]}>
        <h3>DESIGN TEAM</h3>
      </div>
      <div className={styles["TeamMember"]}>
        {design?.map((item, index) => {
          return (
            <div>
              <Designcards key={index} member={item} />
            </div>
          );
        })}
      </div>
      <div>
        <div className={styles["TH"]}>
          <h3>MARKETING TEAM</h3>
        </div>
      </div>
      <div className={styles["TeamMember"]}>
        {marketing?.map((item, index) => {
          return (
            <div>
              <Marketingcards key={index} member={item} />
            </div>
          );
        })}
      </div>
      <div>
        <div className={styles["TH"]}>
          <h3>CONTENT TEAM</h3>
        </div>
      </div>
      <div className={styles["TeamMember"]}>
        {content?.map((item, index) => {
          return (
            <div>
              <Contentcards key={index} member={item} />
            </div>
          );
        })}
      </div>
      <div>
        <div className={styles["TH"]}>
          <h3>TECH TEAM</h3>
        </div>
      </div>
      <div className={styles["TeamMember"]}>
        {tech?.map((item, index) => {
          return (
            <div>
              <Techcards key={index} member={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default TeamMember;
