import React from "react";
import styles from "./TeamCards.module.css";

function Teamcards({ member }) {
  return (
    <div className={styles["TeamMember"]}>
      <div className={styles["MemberImgHolder"]}>
        <div
          className={styles["MemberImg"]}
          style={{ backgroundImage: `url(${member.url})` }}
          alt="image"
        />
      </div>
      <h3>{member.name}</h3>
      <p>{member.post}</p>
    </div>
  );
}

export default Teamcards;
