// import React, { useState, useRef } from "react";
// import { db, storage } from "../firebaseConnect"; // Adjust the path according to your project structure
// import { collection, addDoc } from "firebase/firestore";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid"; // Import the uuid library
// import styles from "./form.module.css";

// function FormComp() {

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     contact: "",
//     grade: "",
//     teamName: "",
//     school: "",
//     district: "",
//     teamMember2: "",
//     teamMember3: "",
//   });

//   const [abstractFile, setAbstractFile] = useState(null);
//   const [errors, setErrors] = useState({});
//   const fileInputRef = useRef(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file && file.type !== "application/pdf") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         abstract: "Only PDF files are allowed",
//       }));
//     } else {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         abstract: null,
//       }));
//       setAbstractFile(file);
//     }
//   };

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.name) newErrors.name = "Name is required";
//     if (!formData.email) {
//       newErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//       newErrors.email = "Email address is invalid";
//     }
//     if (!formData.contact) {
//       newErrors.contact = "Contact number is required";
//     } else if (formData.contact.length !== 10) {
//       newErrors.contact = "Contact number must be 10 digits";
//     }
//     if (!formData.grade) newErrors.grade = "Grade is required";
//     if (!formData.teamName) newErrors.teamName = "Team Name is required";
//     if (!formData.school) newErrors.school = "School is required";
//     if (!formData.district) newErrors.district = "District is required";
//     if (!abstractFile) newErrors.abstract = "Abstract file is required";
//     else if (abstractFile.type !== "application/pdf") {
//       newErrors.abstract = "Only PDF files are allowed";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validate()) return;

//     try {
//       // Generate a unique name for the file
//       const uniqueFileName = `${uuidv4()}_${abstractFile.name}`;

//       // Upload the file to Firebase Storage with the unique name
//       const storageRef = ref(storage, `abstracts/${uniqueFileName}`);
//       const snapshot = await uploadBytes(storageRef, abstractFile);
//       const downloadURL = await getDownloadURL(snapshot.ref);

//       // Add the form data to Firestore, including the file URL and a timestamp
//       await addDoc(collection(db, "students"), {
//         ...formData,
//         abstractFile: downloadURL,
//         timestamp: new Date().toISOString(),
//       });

//       alert("Form Submitted");

//       // Reset form data and file input
//       setFormData({
//         name: "",
//         email: "",
//         contact: "",
//         grade: "",
//         teamName: "",
//         school: "",
//         district: "",
//         teamMember2: "",
//         teamMember3: "",
//       });
//       setAbstractFile(null);
//       fileInputRef.current.value = ""; // Reset file input
//     } catch (error) {
//       alert(error.message);
//     }
//   };

//   return (
//     <div>
//       <div className={styles["form-container"]}>
//         <form className={styles["form"]} onSubmit={handleSubmit}>
//           <h1>Student Registration</h1>

//           <label>
//             Name <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="Name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.name && (
//             <span className={styles["error"]}>{errors.name}</span>
//           )}

//           <label>
//             Grade <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="number"
//             placeholder="Grade"
//             name="grade"
//             value={formData.grade}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.grade && (
//             <span className={styles["error"]}>{errors.grade}</span>
//           )}

//           <label>
//             Team Name <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="Team Name"
//             name="teamName"
//             value={formData.teamName}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.teamName && (
//             <span className={styles["error"]}>{errors.teamName}</span>
//           )}

//           <label>Team Member 2</label>
//           <input
//             type="text"
//             placeholder="Team Member 2"
//             name="teamMember2"
//             value={formData.teamMember2}
//             onChange={handleChange}
//             autoComplete="off"
//           />
//           {errors.teamMember2 && (
//             <span className={styles["error"]}>{errors.teamMember2}</span>
//           )}

//           <label>Team Member 3</label>
//           <input
//             type="text"
//             placeholder="Team Member 3"
//             name="teamMember3"
//             value={formData.teamMember3}
//             onChange={handleChange}
//             autoComplete="off"
//           />
//           {errors.teamMember3 && (
//             <span className={styles["error"]}>{errors.teamMember3}</span>
//           )}

//           <label>
//             Contact Number <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="number"
//             placeholder="Contact Number"
//             name="contact"
//             value={formData.contact}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.contact && (
//             <span className={styles["error"]}>{errors.contact}</span>
//           )}

//           <label>
//             Email <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="email"
//             placeholder="Email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.email && (
//             <span className={styles["error"]}>{errors.email}</span>
//           )}

//           <label>
//             District <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="District"
//             name="district"
//             value={formData.district}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.district && (
//             <span className={styles["error"]}>{errors.district}</span>
//           )}

//           <label>
//             School <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="School"
//             name="school"
//             value={formData.school}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.school && (
//             <span className={styles["error"]}>{errors.school}</span>
//           )}

//           <label>
//             Abstract File <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="file"
//             placeholder="Abstract File"
//             name="abstract"
//             onChange={handleFileChange}
//             autoComplete="off"
//             required
//             ref={fileInputRef} // Add reference to the file input
//           />
//           {errors.abstract && (
//             <span className={styles["error"]}>{errors.abstract}</span>
//           )}

//           <button type="submit">Submit</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default FormComp;
// import React, { useState, useRef } from "react";
// import { db, storage } from "../firebaseConnect"; // Adjust the path according to your project structure
// import { collection, addDoc } from "firebase/firestore";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid"; // Import the uuid library
// import styles from "./form.module.css";
// import emailjs from '@emailjs/browser';

// function FormComp() {

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     contact: "",
//     grade: "",
//     teamName: "",
//     school: "",
//     district: "",
//     teamMember2: "",
//     teamMember3: "",
//   });

//   const [abstractFile, setAbstractFile] = useState(null);
//   const [errors, setErrors] = useState({});
//   const fileInputRef = useRef(null);
//   const emailFormRef = useRef(null); // Reference for the email form

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file && file.type !== "application/pdf") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         abstract: "Only PDF files are allowed",
//       }));
//     } else {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         abstract: null,
//       }));
//       setAbstractFile(file);
//     }
//   };

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.name) newErrors.name = "Name is required";
//     if (!formData.email) {
//       newErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//       newErrors.email = "Email address is invalid";
//     }
//     if (!formData.contact) {
//       newErrors.contact = "Contact number is required";
//     } else if (formData.contact.length !== 10) {
//       newErrors.contact = "Contact number must be 10 digits";
//     }
//     if (!formData.grade) newErrors.grade = "Grade is required";
//     if (!formData.teamName) newErrors.teamName = "Team Name is required";
//     if (!formData.school) newErrors.school = "School is required";
//     if (!formData.district) newErrors.district = "District is required";
//     if (!abstractFile) newErrors.abstract = "Abstract file is required";
//     else if (abstractFile.type !== "application/pdf") {
//       newErrors.abstract = "Only PDF files are allowed";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validate()) return;

//     try {
//       // Generate a unique name for the file
//       const uniqueFileName = `${uuidv4()}_${abstractFile.name}`;

//       // Upload the file to Firebase Storage with the unique name
//       const storageRef = ref(storage, `abstracts/${uniqueFileName}`);
//       const snapshot = await uploadBytes(storageRef, abstractFile);
//       const downloadURL = await getDownloadURL(snapshot.ref);

//       // Add the form data to Firestore, including the file URL and a timestamp
//       await addDoc(collection(db, "students"), {
//         ...formData,
//         abstractFile: downloadURL,
//         timestamp: new Date().toISOString(),
//       });

//       // Send confirmation email
//       await emailjs.sendForm(
//         'service_q9wzzq8',
//         'template_5zorrbq',
//         emailFormRef.current,
//         '1ygC8pEVfxdDt4vCc'
//       );

//       alert("Form Submitted");

//       // Reset form data and file input
//       setFormData({
//         name: "",
//         email: "",
//         contact: "",
//         grade: "",
//         teamName: "",
//         school: "",
//         district: "",
//         teamMember2: "",
//         teamMember3: "",
//       });
//       setAbstractFile(null);
//       fileInputRef.current.value = ""; // Reset file input
//     } catch (error) {
//       alert(error.message);
//     }
//   };

//   return (
//     <div>
//       <div className={styles["form-container"]}>
//         <form className={styles["form"]} onSubmit={handleSubmit}>
//           <h1>Student Registration</h1>

//           <label>
//             Name <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="Name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.name && (
//             <span className={styles["error"]}>{errors.name}</span>
//           )}

//           <label>
//             Grade <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="number"
//             placeholder="Grade"
//             name="grade"
//             value={formData.grade}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.grade && (
//             <span className={styles["error"]}>{errors.grade}</span>
//           )}

//           <label>
//             Team Name <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="Team Name"
//             name="teamName"
//             value={formData.teamName}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.teamName && (
//             <span className={styles["error"]}>{errors.teamName}</span>
//           )}

//           <label>Team Member 2</label>
//           <input
//             type="text"
//             placeholder="Team Member 2"
//             name="teamMember2"
//             value={formData.teamMember2}
//             onChange={handleChange}
//             autoComplete="off"
//           />
//           {errors.teamMember2 && (
//             <span className={styles["error"]}>{errors.teamMember2}</span>
//           )}

//           <label>Team Member 3</label>
//           <input
//             type="text"
//             placeholder="Team Member 3"
//             name="teamMember3"
//             value={formData.teamMember3}
//             onChange={handleChange}
//             autoComplete="off"
//           />
//           {errors.teamMember3 && (
//             <span className={styles["error"]}>{errors.teamMember3}</span>
//           )}

//           <label>
//             Contact Number <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="number"
//             placeholder="Contact Number"
//             name="contact"
//             value={formData.contact}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.contact && (
//             <span className={styles["error"]}>{errors.contact}</span>
//           )}

//           <label>
//             Email <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="email"
//             placeholder="Email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.email && (
//             <span className={styles["error"]}>{errors.email}</span>
//           )}

//           <label>
//             District <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="District"
//             name="district"
//             value={formData.district}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.district && (
//             <span className={styles["error"]}>{errors.district}</span>
//           )}

//           <label>
//             School <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="text"
//             placeholder="School"
//             name="school"
//             value={formData.school}
//             onChange={handleChange}
//             autoComplete="off"
//             required
//           />
//           {errors.school && (
//             <span className={styles["error"]}>{errors.school}</span>
//           )}

//           <label>
//             Abstract File <span className={styles["required"]}>*</span>
//           </label>
//           <input
//             type="file"
//             placeholder="Abstract File"
//             name="abstract"
//             onChange={handleFileChange}
//             autoComplete="off"
//             required
//             ref={fileInputRef} // Add reference to the file input
//           />
//           {errors.abstract && (
//             <span className={styles["error"]}>{errors.abstract}</span>
//           )}

//           <button type="submit">Submit</button>
//         </form>
        
//         {/* Hidden form for email sending */}
//         <form ref={emailFormRef} style={{ display: 'none' }}>
//           <input type="hidden" name="user_name" value={formData.name} />
//           <input type="hidden" name="user_email" value={formData.email} />
//           <textarea
//             name="message"
//             value={`This is to confirm your registration from IPF NITC and your id is ${uniqueFileName}.`}
//           />
//         </form>
//       </div>
//     </div>
//   );
// };

// export default FormComp;
import React, { useState, useRef } from "react";
import { db, storage } from "../firebaseConnect"; // Adjust the path according to your project structure
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid"; // Import the uuid library
import styles from "./form.module.css";
import emailjs from '@emailjs/browser';

function FormComp() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    grade: "",
    teamName: "",
    school: "",
    district: "",
    teamMember2: "",
    teamMember3: "",
  });

  const [abstractFile, setAbstractFile] = useState(null);
  const [uniqueFileName, setUniqueFileName] = useState(""); // State to store the unique file name
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const emailFormRef = useRef(null); // Reference for the email form

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        abstract: "Only PDF files are allowed",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        abstract: null,
      }));
      setAbstractFile(file);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.contact) {
      newErrors.contact = "Contact number is required";
    } else if (formData.contact.length !== 10) {
      newErrors.contact = "Contact number must be 10 digits";
    }
    if (!formData.grade) newErrors.grade = "Grade is required";
    if (!formData.teamName) newErrors.teamName = "Team Name is required";
    if (!formData.school) newErrors.school = "School is required";
    if (!formData.district) newErrors.district = "District is required";
    if (!abstractFile) newErrors.abstract = "Abstract file is required";
    else if (abstractFile.type !== "application/pdf") {
      newErrors.abstract = "Only PDF files are allowed";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      // Generate a unique name for the file
      const uniqueFileNameGenerated = `${uuidv4()}_${abstractFile.name}`;
      setUniqueFileName(uniqueFileNameGenerated); // Update the state with the unique file name

      // Upload the file to Firebase Storage with the unique name
      const storageRef = ref(storage, `abstracts/${uniqueFileNameGenerated}`);
      const snapshot = await uploadBytes(storageRef, abstractFile);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Add the form data to Firestore, including the file URL and a timestamp
      await addDoc(collection(db, "students"), {
        ...formData,
        abstractFile: downloadURL,
        timestamp: new Date().toISOString(),
      });

      // Send confirmation email
      emailjs.sendForm(
        'service_q9wzzq8',
        'template_5zorrbq',
        emailFormRef.current,
        '1ygC8pEVfxdDt4vCc'
      )
      .then((result) => {
        console.log(result.text);
        console.log("Email sent successfully");
      }, (error) => {
        console.log(error.text);
      });
      
      alert("Form Submitted");

      // Reset form data and file input
      setFormData({
        name: "",
        email: "",
        contact: "",
        grade: "",
        teamName: "",
        school: "",
        district: "",
        teamMember2: "",
        teamMember3: "",
      });
      setAbstractFile(null);
      fileInputRef.current.value = ""; // Reset file input
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <div className={styles["form-container"]}>
        <form className={styles["form"]} onSubmit={handleSubmit}>
          <h1>Student Registration</h1>

          <label>
            Name <span className={styles["required"]}>*</span>
          </label>
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.name && (
            <span className={styles["error"]}>{errors.name}</span>
          )}

          <label>
            Grade <span className={styles["required"]}>*</span>
          </label>
          <input
            type="number"
            placeholder="Grade"
            name="grade"
            value={formData.grade}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.grade && (
            <span className={styles["error"]}>{errors.grade}</span>
          )}

          <label>
            Team Name <span className={styles["required"]}>*</span>
          </label>
          <input
            type="text"
            placeholder="Team Name"
            name="teamName"
            value={formData.teamName}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.teamName && (
            <span className={styles["error"]}>{errors.teamName}</span>
          )}

          <label>Team Member 2</label>
          <input
            type="text"
            placeholder="Team Member 2"
            name="teamMember2"
            value={formData.teamMember2}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.teamMember2 && (
            <span className={styles["error"]}>{errors.teamMember2}</span>
          )}

          <label>Team Member 3</label>
          <input
            type="text"
            placeholder="Team Member 3"
            name="teamMember3"
            value={formData.teamMember3}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.teamMember3 && (
            <span className={styles["error"]}>{errors.teamMember3}</span>
          )}

          <label>
            Contact Number <span className={styles["required"]}>*</span>
          </label>
          <input
            type="number"
            placeholder="Contact Number"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.contact && (
            <span className={styles["error"]}>{errors.contact}</span>
          )}

          <label>
            Email <span className={styles["required"]}>*</span>
          </label>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.email && (
            <span className={styles["error"]}>{errors.email}</span>
          )}

          <label>
            District <span className={styles["required"]}>*</span>
          </label>
          <input
            type="text"
            placeholder="District"
            name="district"
            value={formData.district}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.district && (
            <span className={styles["error"]}>{errors.district}</span>
          )}

          <label>
            School <span className={styles["required"]}>*</span>
          </label>
          <input
            type="text"
            placeholder="School"
            name="school"
            value={formData.school}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {errors.school && (
            <span className={styles["error"]}>{errors.school}</span>
          )}

          <label>
            Abstract File <span className={styles["required"]}>*</span>
          </label>
          <input
            type="file"
            placeholder="Abstract File"
            name="abstract"
            onChange={handleFileChange}
            autoComplete="off"
            required
            ref={fileInputRef} // Add reference to the file input
          />
          {errors.abstract && (
            <span className={styles["error"]}>{errors.abstract}</span>
          )}

          <button type="submit">Submit</button>
        </form>
        
        {/* Hidden form for email sending */}
        <form ref={emailFormRef} style={{ display: 'none' }}>
          <input type="text" name="user_name" value={formData.name} />
          <input type="email" name="reply_to" value={formData.email} />
          <textarea
            name="message"
            value={`This is to confirm your registration for MIC 2024 from IPF NITC and your id is ${uniqueFileName}.`}
          />
        </form>
      </div>
    </div>
  );
};

export default FormComp;
