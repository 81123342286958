import { useState, useEffect } from "react";
import { db } from "../firebaseConnect";
import { collection, onSnapshot } from "firebase/firestore";
import styles from "./events.module.css";
import { Link, useNavigate } from "react-router-dom";

function Events() {
  const [hoveredChild, setHoveredChild] = useState(0);

  const handleMouseEnter = (index) => {
    setHoveredChild(index);
  };

  const [events, setEvents] = useState([]);
  const eventsColref = collection(db, "events");

  useEffect(() => {
    onSnapshot(eventsColref, (s) => {
      const data = [];
      s.forEach((doc) => {
        data.push({ ...doc.data() });
      });
      setEvents(data);
    });
  }, []);

  return (
    <div className={styles["event-wrapper"]} id="eventsSection">
      <div className={styles["heading"]}> OUR EVENTS</div>
      <div className={styles["events"]}>
        {events.map((child, index) => (
          <Link
            key={index}
            to={`/Events/${child.title}`}
            onMouseEnter={() => handleMouseEnter(index)}
            style={{ backgroundImage: `url(${child.url})` }}
            className={`${styles["child"]} ${
              hoveredChild !== null &&
              (index === hoveredChild - 1 || index === hoveredChild + 1
                ? styles["less-shrinkable"]
                : "")
            } ${hoveredChild === index ? styles["hovered"] : ""}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Events;
