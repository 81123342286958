import React from "react";
import styles from "./AboutUs.module.css";

function AboutUs() {
  const aboutUs =
    "NITC's Industrial and Planning Forum isn't just another club. Since the 60s its's been igniting entrepreneurial spirits, transforming students into impact-makers through industry exposure, MUN collaborations and captivating insights into unconventional success stories.";

  return (
    <div className={styles["AboutUs-wrapper"]} id="aboutUsSection">
      <div className={styles["AboutUs-heading"]}>
        <h1 className={styles["AboutUs"]}> About Us </h1>
      </div>
      <div className={styles["AboutUs-content"]}>
        <p className={styles["AboutUs-paragraph"]}>{aboutUs}</p>
      </div>
    </div>
  );
}

export default AboutUs;
