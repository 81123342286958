import HeroSection from "../components/HeroSection";
import AboutUs from "../components/AboutUs";
import Events from "../components/Events";
import Series from "../components/Series";
import { useEffect } from "react";

function Landing() {
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
  return (
    <div>
      <HeroSection />
      <AboutUs />
      <Events />
      <Series />
    </div>
  );
}

export default Landing