import React from "react";
import { Link } from "react-scroll";
import styles from "./hero-section.module.css";

function HeroSection() {
  return (
    <div className={styles["hero-wrapper"]} id="home">
      <div className={styles["background-img"]}></div>
      <div className={styles["logo-heading-wrapper"]}>
        <img className={styles["logo"]} src="/images/logo.svg" alt="logo" />
        <h1 className={styles["heading"]}>INDUSTRIAL & PLANNING FORUM</h1>
      </div>
      <div className={styles["scroll-down"]}>
        <Link to="aboutUsSection">
          <p style={{ color: "white" }}>Scroll Down</p>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
