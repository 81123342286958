import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar";
import Landing from "./pages/Landing";
import Events from "./components/Events";
import Team from "./pages/Team";
import About from "./pages/About";
import Form from "./pages/Form";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Events/:id" element={<Events />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/About" element={<About />} />
        <Route path="/MicRegistations" element={<Form />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
